import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";

import CaseList2 from "../sections/explore-our-products/CaseList2";
import CTA from "../sections/case-study/CTA";
import Hero from "../sections/explore-our-products/Hero"
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Content3 from "../sections/landing1/Content3";
import Content5 from "../sections/landing2/Content2";
import Content4 from "../sections/landing1/Content4";

const CaseStudy = () => {
  return (
    <>
      <PageWrapper headerDark>
        <Helmet>
          <title>Explore Our Products</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero title="Explore Our Products">
        </Hero>
        <Content1 noTitle />
        <Content2 />
        <CaseList2 />
        <Content5 bg="#fff" />
        <Content3 />
        <Content4 bg="#fff" />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudy;
