import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgContentBig from "../../assets/image/radios/smart-trio.png";
// @ts-expect-error false positive image import failure
import head1 from "../../assets/image/talking/head1.png";
// @ts-expect-error false positive image import failure
import head2 from "../../assets/image/talking/head2.png";

interface ThemeProps {
  theme: DefaultTheme;
}

const ShapeCard = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: 20%;
  left: 0%;
  transform: scale(0.85);
  opacity: 0.9;
  @media ${device.sm} {
    left: 0;
    transform: scale(1);
  }
  @media ${device.md} {
    left: 0%;
  }
`;
const ShapeCard2 = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: 4%;
  right: -5%;
  transform: scale(0.85);
  opacity: 0.9;
  @media ${device.sm} {
    right: -5%;
    transform: scale(1);
  }
  @media ${device.md} {
    right: -5%;
  }
`;
const HeadImage = styled.img`
  width: 55px;
  height: 55px;
`;
const ULStyled = styled.ul<ThemeProps>`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: flex-start;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;
    width: 250px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.primary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Content2 = ({ bg = "#F7F7FB" }: { bg?: string }) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section bg={bg}>
      <Container>
        <Row className="align-items-center">
          <Col lg="5" md="12" className="order-lg-2 mb-4">
            <div className="position-relative text-lg-right">
              <Box
                className=""
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img src={imgContentBig} alt="" className="img-fluid" />
              </Box>

              {/* <ShapeCard
                bg="warning"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  pl={3}
                >
                  <HeadImage margin-right={2} src={head1} alt="" />
                </Box>

                <Box ml={3}>
                  <Title textColor="light" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "I've just had a man leave the store without paying, red top and blue jeans."
                  </Title>
                </Box>
              </ShapeCard>

              <ShapeCard2
                bg="secondary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center justify-content-end"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1500"
              >
                <Box pr="10px">
                  <Title textColor="light" textAlign="right" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "We've got him on camera and police are on their way."
                  </Title>
                </Box>
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  ml={3}
                >
                  <HeadImage margin-left={2} src={head2} alt="" />
                </Box>

              </ShapeCard2> */}
            </div>
          </Col>
          <Col lg="7" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>More than just a radio.</Title>
                <Text mb={4}>
                  Our devices come in many shapes and sizes, with differing
                  feature sets and pricing options tailored to your needs and
                  budget. All our hire contracts include our comprehensive
                  24/7technical support package, ensuring you always have a
                  working radio. <br />
                  <br />
                  All our models are interoperable and boast the same key
                  features as standard:
                </Text>

                <ULStyled>
                  <li>Digital HD audio</li>
                  <li>SOS panic alarms</li>
                  <li>Colour displays</li>
                  <li>Body camera options</li>
                </ULStyled>
                <Link to="/smart-radio">
                  <Button mt={3}>Learn More</Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
