import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import imgCase1 from "../../assets/image/heros/town.png";

const SectionStyled = styled(Section)`
  background: url(${imgCase1}) no-repeat;
  background-size: cover;
  background-position: center right;
  position: relative;
`;

const HeroOverlay = styled(Section)`
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: .4;
`;

interface Props {
  title?: string;
}

const Hero: React.FC<Props> = ({ title, children, ...rest }) => {
  return (
    <>
      <SectionStyled
        pt={["120px!important", null, "100px!important"]}
        pb={["50px!important", null, "80px!important"]}
        {...rest}
      >
        <HeroOverlay />
        <Container>
          <Row>
            <Col lg="8">
              <div>
                <Title variant="hero" textColor="light">{title}</Title>
                <Text textColor="light">{children}</Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
